
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileMapper, Order } from '@/models/index'

@Component({
  name: 'FileMapperTable'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private step!: number

  private fileMappers: FileMapper[] = []
  private fileMappersLoading = false
  createFileMapper = false

  created() {
    this.getFileMappers()
  }

  private async getFileMappers() {
    this.fileMappersLoading = true
    const { data } = await FileMapper.order({ createdAt: 'desc' }).all()
    this.fileMappers = data
    this.fileMappersLoading = false
  }

  async changeFileMapper(fileMapper: FileMapper) {
    const currentFileMapper = this.fileMappers.find(fileMapper => fileMapper.default)
    if (currentFileMapper) await this.setDefaultbyDefault(currentFileMapper, false)
    await this.setDefaultbyDefault(fileMapper, true)
    await this.getFileMappers()

    this.$notify({
      title: this.$t('orders.seller.codesDialog.manageFileMapper.notification.success.title') as string,
      message: this.$t('orders.seller.codesDialog.manageFileMapper.notification.success.subtitle') as string,
      type: 'success',
      duration: 2000
    })
  }

  async setDefaultbyDefault(fileMapper: FileMapper, byDefault: boolean): Promise<void> {
    try {
      fileMapper.default = byDefault
      await fileMapper.save()
    } catch (e) {
      this.$notify({
        title: this.$t('orders.seller.codesDialog.manageFileMapper.notification.error.title') as string,
        message: this.$t('orders.seller.codesDialog.manageFileMapper.notification.error.subtitle') as string,
        type: 'error',
        duration: 2000
      })
    }
  }
}
