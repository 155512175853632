
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Order, PurchaseInfo } from '@/models/index'

@Component({
  name: 'OrderSummaryFooter'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: false, default: null }) private sellerPurchaseInfo!: PurchaseInfo
  @Prop({ required: false, default: null }) private wholesalerPurchaseInfo!: PurchaseInfo
  @Prop({ required: false, default: false }) private loading!: boolean

  get creditNoteTooltipMessage() {
    return this.creditNoteCredited ? this.$t('orderFormDialog.summary.haveCredited') : this.$t('orderFormDialog.summary.pendingPayment')
  }

  get creditNoteCredited() {
    return this.order?.balanceMove?.creditedAt !== undefined
  }

  get showCreditNoteStatus() {
    return this.order?.balanceMove?.amount > 0
  }

  get creditNote() {
    if (this.order.isPersisted) return this.order.balanceEarned

    if (!this.wholesalerPurchaseInfo.isPartialOffset() && !this.wholesalerPurchaseInfo.isVatPaid()) return 0
    else return this.order.chargeableAmount - this.order.discountedAmount
  }

  get deferredUsable() {
    if (this.order.isPersisted) return this.order.deferredAmountUsage

    if (!this.wholesalerPurchaseInfo.isDeferred()) return 0

    if (this.deferredAmountRemaining < 0) return this.order.payableAmount
    else return this.deferredAmountRemaining < this.order.payableAmount ? this.deferredAmountRemaining : this.order.payableAmount
  }

  get deferredAmountRemaining() {
    const sellerRemaining = this.sellerPurchaseInfo.deferredAmountAvailable
    const wholesalerRemaining = this.wholesalerPurchaseInfo.deferredAmountAvailable

    if (wholesalerRemaining < 0) return -1
    else if (sellerRemaining < 0) return wholesalerRemaining
    else return sellerRemaining < wholesalerRemaining ? sellerRemaining : wholesalerRemaining
  }
}
