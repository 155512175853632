
import { Component, Vue } from 'vue-property-decorator'
import { Order } from '@/models/index'

@Component({
  name: 'SellerOrdersList',
  components: {
    SellerOrdersHeader: () => import('./components/SellerOrdersHeader.vue'),
    FiltersDrawer: () => import('./components/FiltersDrawer.vue'),
    OrderCardDesktop: () => import('./components/OrderCardDesktop.vue'),
    OrderCardMobile: () => import('./components/OrderCardMobile.vue'),
    DeliveringCodesDialog: () => import('./components/delivering-codes-dialog/index.vue')
  }
})
export default class extends Vue {
  private orders: Order[] = []
  private detailedOrder: Order = new Order()

  private totalOrders = 0
  private loading = true
  private windowWidth = window.innerWidth
  private deleteOrderLoading: boolean[] = []
  private dialogDeliveringVisible = false
  private listQuery: any = {
    page: 1,
    number: null,
    deliveryStatus: null,
    dateRange: null
  }

  private created() {
    this.getOrders()

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }

  get mdAndUp() {
    return this.windowWidth >= 690
  }

  private async getOrders(): Promise<void> {
    this.loading = true
    const createdAtGte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange[0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.listQuery.dateRange ? this.$moment(String(this.listQuery.dateRange[1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } =
      await Order
        .where({ number: { prefix: this.listQuery.number } })
        .where({ deliveryStatus: this.listQuery.deliveryStatus })
        .where({ createdAt: { gte: createdAtGte, lte: createdAtLte } })
        .includes(['giftCard', 'cardDistributions', 'deliveries'])
        .page(this.listQuery.page)
        .per(10)
        .order({ createdAt: 'desc' })
        .stats({ total: 'count' })
        .all()

    this.orders.push(...data)
    this.totalOrders = meta.stats.total.count
    this.listQuery.page += 1
    this.deleteOrderLoading.length = this.orders.length
    this.deleteOrderLoading.fill(false)
    this.loading = false
  }

  private async handleDeliveryUpdate() {
    const index = this.orders.findIndex(v => v.id === this.detailedOrder.id)

    if (!this.detailedOrder?.id) return

    const { data: order } = await Order.includes(['giftCard', 'cardDistributions', 'deliveries']).find(this.detailedOrder.id)
    this.orders.splice(index, 1, order)
    this.detailedOrder = order
  }

  private setFilters(filters: any) {
    this.listQuery = { ...filters }
    this.refresh()
  }

  private refresh() {
    this.listQuery.page = 1
    this.totalOrders = 0
    this.orders.splice(0, this.orders.length)
    this.getOrders()
  }
}
