
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Order } from '@/models/index'

@Component({
  name: 'DeliveryCodesDetails'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private step!: number
}
