
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Delivery, Order } from '@/models/index'
import { Form as ElForm } from 'element-ui/types/element-ui'

@Component({
  name: 'DeliveringCodesDialog',
  components: {
    DirectUpload: () => import('@/components/DirectUpload/index.vue'),
    DeliveryCodesDialog: () => import('./components/DeliveryCodesDetails.vue'),
    FileMapperTable: () => import('./components/FileMapperTable.vue'),
    FileCodesErrors: () => import('./components/FileCodesErrors.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true, default: null }) private order!: Order
  @Prop({ required: true }) private visible!: boolean

  delivery: Delivery = new Delivery({ order: this.order })
  loading = false

  step = 1
  fileDetails = null
  deliverySuccess = false
  orderDeliveriesError: any[] = []

  rules: any = {
    activateAt: [
      { required: true, message: this.$t('orders.seller.codesDialog.rules.activationDateRequired'), trigger: 'blur' }
    ],
    codesFile: [
      { required: true, message: this.$t('orders.seller.codesDialog.rules.codeFileRequired'), trigger: 'blur' }
    ]
  }

  created() {
    if (this.lastDeliveryHasErrors) {
      this.delivery = this.lastDelivery as Delivery
      this.delivery.codesFile = ''
      this.step = 3
    }

    this.orderDeliveriesError = this.order.deliveries
  }

  get lastDelivery() {
    return this.order?.deliveries[this.order?.deliveries.length - 1]
  }

  get lastDeliveryHasErrors() {
    return this.order?.deliveries[this.order?.deliveries.length - 1]?.fileErrors.length > 0 || this.order?.deliveries[this.order?.deliveries.length - 1]?.match === false
  }

  async submitForm(): Promise<void> {
    (this.$refs.deliveryForm as ElForm).validate(async(valid) => {
      if (valid) {
        this.loading = true

        try {
          await this.delivery.save({ with: 'order.id' })

          this.orderDeliveriesError.push(this.delivery)
          this.$emit('update', this.delivery)
          this.deliverySuccess = true
          this.loading = false
          this.step++
        } catch (err) {
          this.loading = false
          this.step++
        }
      }
    })
  }

  private uploadCallback(file: any, blob: any) {
    this.fileDetails = file.blob
    this.delivery.codesFile = file.blob.signed_id
    this.step++
  }

  private newDelivery() {
    this.step = 1
    this.delivery = new Delivery({ order: this.order })
  }
}
