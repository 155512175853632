
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Order } from '@/models/index'
import { clipboardSuccess } from '@/utils/clipboard'

@Component({
  name: 'OrderCardMobile'
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private deleteLoading!: boolean

  private clipboardSuccess = clipboardSuccess

  get percentage(): number {
    return Number(((this.order.deliveriesCodesSum() / this.order.deliveriesCodesTotalSum()) * 100).toFixed(0))
  }

  get lastDelivery() {
    return this.order?.deliveries[this.order?.deliveries.length - 1]
  }

  get lastDeliveryHasErrors() {
    return this.order?.deliveries[this.order?.deliveries.length - 1]?.fileErrors.length > 0 || this.order?.deliveries[this.order?.deliveries.length - 1]?.match === false
  }

  customColorMethod(percentage: number) {
    if (percentage < 30) return '#ff2600'
    else if (percentage < 70) return '#FFBA00'
    else return '#04C100'
  }
}
