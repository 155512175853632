
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Delivery, Order } from '@/models/index'

import DownloadManagerDialog from '@/components/DownloadManagerDialog/index.vue'

@Component({
  name: 'CardDeliveries',
  components: {
    DownloadManagerDialog
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order

  get amountTotalOfCodes(): number {
    return this.order.deliveries.reduce((acc: number, delivery: Delivery) => {
      return acc + Number(delivery.totalCodesNumber)
    }, 0)
  }

  get amountTotalOfCodesByAPI(): number {
    return this.order.deliveries.reduce((acc: number, delivery: Delivery) => {
      return acc + Number(delivery.distributedByApiCount)
    }, 0)
  }

  get amountTotalOfCodesByFile(): number {
    return this.order.deliveries.reduce((acc: number, delivery: Delivery) => {
      return acc + Number(delivery.distributedByFileCount)
    }, 0)
  }

  get anyDeliveryDownloaded(): boolean {
    return this.order.deliveries.some((delivery: Delivery) => delivery.firstDownloadedAt)
  }

  get allDeliveriesDownloaded(): boolean {
    return this.order.deliveries.every((delivery: Delivery) => delivery.firstDownloadedAt)
  }
}
