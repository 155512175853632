
import { Component, Vue } from 'vue-property-decorator'

import { useUserStore } from '@/stores/user'
import { Order } from '@/models'

@Component({
  name: 'OrdersList',
  components: {
    OrdersHeader: () => import('./components/OrdersHeader.vue'),
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    PanelGroupCustom: () => import('@/components/PanelGroupCustom/index.vue'),
    OrderFormDialog: () => import('@/components/OrderFormDialog/index.vue'),
    WrapperOrdersCards: () => import('./components/WrapperOrdersCards.vue'),
    FilterDrawer: () => import('./components/FiltersDrawer.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  private buyersOrdersWholesaler = false

  private modeSplit = false
  private dialogFormVisible = false

  get isWholesaler(): boolean {
    return this.userStore.roles.includes('wholesaler')
  }

  created(): void {
    if (this.isWholesaler) this.modeSplit = true
  }

  handleOrderCreated(order: Order) {
    ;(this.$refs.mainTable as any).handleCreatedOrder(order)
    if (!this.isWholesaler) (this.$refs.headerFilters as any).getPrepaidAccount()
  }

  private refresh(filters: any) {
    (this.$refs.mainTable as any).setFilters(filters)
  }

  resetFilters(): void {
    if (this.modeSplit) {
      this.buyersOrdersWholesaler = false
      ;(this.$refs.headerFilters as any).resetFilters()
    }
  }
}
