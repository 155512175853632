
import { Component, Prop, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'
import { Balance } from '@/models'

@Component({
  name: 'OrdersHeader'
})
export default class extends Vue {
  @Prop({ required: false, default: 'Title' }) private title!: string

  userStore: any = useUserStore()

  prepaidAccount = 0

  get isBuyer() {
    return this.userStore.roles.includes('buyer')
  }

  created(): void {
    if (this.isBuyer) this.getPrepaidAccount()
  }

  async getPrepaidAccount() {
    const { data } = await Balance.all()

    this.prepaidAccount = data[0] ? data.reduce((acc: any, prev: any) => acc + prev.amount, 0) : 0
  }
}
