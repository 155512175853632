
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { useUserStore } from '@/stores/user'
import { Delivery, Order, Sku } from "@/models";
import { printErrors } from '@/utils/printErrors'

@Component({
  name: 'WrapperOrdersCards',
  components: {
    OrderCardDesktop: () => import('./OrderCardDesktop.vue'),
    OrderCardMobile: () => import('./OrderCardMobile.vue'),
    OrderFormDialog: () => import('@/components/OrderFormDialog/index.vue'),
    DetailsDialog: () => import('./details-dialog/index.vue'),
    FilterDrawer: () => import('./FiltersDrawer.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private split!: boolean
  @Prop({ required: true }) private buyersOrdersWholesaler!: boolean
  @Prop({ required: false, default: 'Title table' }) private titleSplitMode!: string

  userStore: any = useUserStore()

  private windowWidth = window.innerWidth
  private expand = false

  private dialogFormVisible = false
  private orderToEdit: Order | null = null

  private dialogDetailsVisible = false
  private detailedOrder: Order | null = null

  private firstLoading = true
  private loading = false
  private orders: Order[] = []
  private lastPageReached = false
  private tableFilters: any = {
    page: 1,
    bankTransferRef: undefined,
    number: undefined,
    dateRange: null,
    giftCardId: undefined,
    status: null,
    paymentStatus: null,
    deliveryStatus: null,
    buyerId: null
  }

  get isWholesaler(): boolean {
    return this.userStore.roles.includes('wholesaler')
  }

  get mdAndUp() {
    return this.windowWidth >= 992
  }

  private created() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    this.getOrders()
  }

  private async getOrders(): Promise<void> {
    this.loading = true

    const createdAtGte = this.tableFilters.dateRange ? this.$moment(String(this.tableFilters.dateRange[0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.tableFilters.dateRange ? this.$moment(String(this.tableFilters.dateRange[1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data } =
      await Order
        .includes([
          { giftCard: ['defaultGiftCardSeller', 'skus'] },
          { cardDistributions: 'sku' },
          'invoices',
          'buyer',
          'seller',
          'deliveries',
          'balanceMove'
        ])
        .where({
          number: { prefix: this.tableFilters.number },
          customNumber: { prefix: this.tableFilters.bankTransferRef },
          createdAt: { gte: createdAtGte, lte: createdAtLte },
          status: this.tableFilters.status,
          paymentStatus: this.tableFilters.paymentStatus,
          deliveryStatus: this.tableFilters.deliveryStatus,
          giftCardId: this.tableFilters.giftCardId,
          buyerId: this.buyersOrdersWholesaler ? this.tableFilters.buyerId : null,
          bulk: this.isWholesaler && !this.buyersOrdersWholesaler
        })
        .merge({ skus: Sku.where({ faceValueFree: false }) })
        .page(this.tableFilters.page)
        .per(10)
        .order({ createdAt: 'desc' })
        .all()

    if (data.length === 0) this.lastPageReached = true
    this.orders.push(...data)
    this.tableFilters.page += 1

    this.loading = false
    this.firstLoading = false
  }

  private refresh(): void {
    this.orders = []
    this.tableFilters.page = 1
    this.lastPageReached = false
    this.firstLoading = true
    this.getOrders()
  }

  private process(order: Order) {
    if (order.detailsNeeded()) this.handleUpdate(order)
    else order.process()
  }

  async handleCreatedOrder(order: Order) {
    const { data } = await Order
      .includes([
        { giftCard: ['defaultGiftCardSeller', 'skus'] },
        { cardDistributions: 'sku' },
        'invoices',
        'buyer',
        'seller',
        'deliveries',
        'balanceMove'
      ])
      .where({ id: order.id })
      .first()
    this.orders.unshift(data!.dup())
  }

  private handleUpdate(order: Order) {
    this.orderToEdit = order.dup()
    this.dialogFormVisible = true
  }

  private async updateOrderDelivery(dataOrder: any) {
    const orderIndex = this.orders.findIndex((order: any) => order.id === dataOrder.orderId)

    if (orderIndex !== -1) {
      let orderToUpdate = this.orders[orderIndex].dup()

      const deliveryIndex = orderToUpdate.deliveries.findIndex((delivery: any) => delivery.id === dataOrder.deliveryId)

      if (deliveryIndex !== -1) {
        const { data } = await Delivery.find(dataOrder.deliveryId)

        orderToUpdate.deliveries.splice(deliveryIndex, 1, data)
        this.orders.splice(orderIndex, 1, orderToUpdate)
      }
    }
  }

  private handleOrderCreated(order: Order) {
    this.orders.unshift(order)
  }

  private handleOrderUpdated(order: Order) {
    const index = this.orders.findIndex(v => v.id === order.id)
    this.orders.splice(index, 1, order)
  }

  private async handleDelete(data: Order, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      printErrors(data.errors)
    } else {
      this.$notify({
        title: this.$t('order.notification.delete.title') as string,
        message: this.$t('order.notification.delete.message') as string,
        type: 'success',
        duration: 2000
      })
      this.orders.splice(index, 1)
    }
  }

  private setFilters(filters: any) {
    this.tableFilters = filters || this.tableFilters

    this.$nextTick(() => {
      this.refresh()
    })
  }

  @Watch('split')
  resetFilters(): void {
    this.tableFilters = {
      page: 1,
      bankTransferRef: undefined,
      number: undefined,
      dateRange: null,
      giftCardId: undefined,
      status: null,
      paymentStatus: null,
      deliveryStatus: null,
      buyerId: null
    }
    this.refresh()
  }
}
