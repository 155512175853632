
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'FiltersDrawer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  private showFilters = false

  private filters: any = {
    number: null,
    deliveryStatus: null,
    dateRange: null
  }

  private deliveryStatus = [
    {
      label: this.$t('orders.seller.status.complete'),
      value: 'delivered'
    },
    {
      label: this.$t('orders.seller.status.partial'),
      value: 'in_delivery'
    },
    {
      label: this.$t('orders.seller.status.waiting'),
      value: 'undelivered'
    }
  ]

  get filtersActive() {
    return Object.keys(this.filters).filter(i => {
      if (this.filters[i] !== null) {
        if ((typeof this.filters[i] === 'object' && this.filters[i].length > 0) || (typeof this.filters[i] === 'string' && this.filters[i] !== '')) {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', this.filters)
  }
}
