
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Buyer, GiftCard } from '@/models'

@Component({
  name: 'FiltersDrawer',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private isWholesaler!: boolean
  @Prop({ required: false, default: '' }) private btnSize!: string

  private showFilters = false
  private dataFetched = false

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false
  private paginationGiftCards = {
    page: 1,
    perPage: 999
  }

  private buyers: Buyer[] = []
  private buyersLoading = false

  private listQuery: any = {
    page: 1,
    bankTransferRef: null,
    number: null,
    dateRange: null,
    giftCardId: null,
    status: null,
    paymentStatus: null,
    deliveryStatus: null,
    buyerId: null
  }

  private paymentStatus = [
    { value: 'refunded' },
    { value: 'unpaid' },
    { value: 'late' },
    { value: 'paid' }
  ]

  private optionsStatus = [
    { value: 'waiting' },
    { value: 'processing' },
    { value: 'fulfilled' }
  ]

  private deliveryStatus = [
    {
      label: this.$t('deliveryStatus.delivered'),
      value: 'delivered'
    },
    {
      label: this.$t('deliveryStatus.in_delivery'),
      value: 'in_delivery'
    },
    {
      label: this.$t('deliveryStatus.undelivered'),
      value: 'undelivered'
    }
  ]

  get filtersActive() {
    return Object.keys(this.listQuery).filter(i => {
      if (this.listQuery[i] !== null && this.listQuery[i] !== '') {
        if ((typeof this.listQuery[i] === 'object' && this.listQuery[i].length > 0) || typeof this.listQuery[i] !== 'object') {
          if (i !== 'page') {
            return i
          }
        }
      }
    }).length
  }

  @Watch('showFilters', { immediate: true })
  private fetchData(value: boolean) {
    if (!this.dataFetched && value) {
      this.getGiftCards()
      if (this.isWholesaler) this.getBuyers()

      this.dataFetched = true
    }
  }

  private async getGiftCards() {
    this.giftCardsLoading = true

    const { data } = await GiftCard
      .per(this.paginationGiftCards.perPage)
      .page(this.paginationGiftCards.page)
      .order('name')
      .all()

    this.giftCards.push(...data)

    this.giftCardsLoading = false
  }

  private async getBuyers() {
    const { data } = await Buyer.per(999).all()

    this.buyers = data
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.listQuery.giftCardId = giftCardId
  }

  resetFilters() {
    this.listQuery = {
      page: 1,
      bankTransferRef: null,
      number: null,
      dateRange: null,
      giftCardId: null,
      status: null,
      paymentStatus: null,
      deliveryStatus: null,
      buyerId: null
    }
  }

  refresh() {
    this.showFilters = false
    this.$emit('applyFilters', { ...this.listQuery })
  }
}
