
import { Component, Vue } from 'vue-property-decorator'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'Orders',
  components: {
    OrdersList: () => import('@/views/orders/orders-list/index.vue'),
    SellerOrdersList: () => import('@/views/orders/seller-orders-list/index.vue'),
    CaretakerOrdersList: () => import('@/views/orders/caretaker-orders-list/index.vue')
  }
})
export default class extends Vue {
  userStore: any = useUserStore()

  get roles() {
    return this.userStore.roles
  }
}
