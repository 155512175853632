
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Invoice, Order } from "@/models/index";

@Component({
  name: 'DetailsDialog',
  components: {
    OrderSummary: () => import('./components/OrderSummary.vue'),
    OrderSummaryFooter: () => import('@/views/orders/orders-list/components/OrderSummaryFooter.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true, default: null }) private order!: Order
  @Prop({ required: false, default: null }) private orderId!: string
  @Prop({ required: true }) private visible!: boolean

  private fetchedOrder: Order = new Order()
  private invoice: Invoice | null = null
  private activeNames = ['1']

  get activeStep() {
    switch (this.order.deliveryStatus) {
      case 'in_delivery': return 1
      case 'delivered': return 3
      default: return 0
    }
  }

  get order_() {
    return this.order || this.fetchedOrder
  }

  @Watch('visible')
  private onVisibleChange(value: boolean) {
    if (value && !this.order) {
      this.fetchOrder()
    }
  }

  private async fetchOrder() {
    const { data } = await Order.find(this.orderId)

    this.fetchedOrder = data
  }

  @Watch('order_', { immediate: true })
  private async getInvoiceByOrderNumber() {
    const { data } = await Invoice
      .includes(['commissionInvoice'])
      .where({ orderNumber: this.order_.number })
      .where({ mode: 'standard' })
      .first()

    this.invoice = data
  }

  private showPdf(url: string) {
    window.open(url)
  }

  private progressFormat(percentage: number) {
    return `${this.$t('detailsDialog.remainingDraw')}: ${percentage}%`
  }
}
