
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'FileCodesErrors'
})
export default class extends Vue {
  @Prop({ required: true }) private fileErrors!: any[]
  @Prop({ required: true }) private step!: number

  get lastDelivery() {
    return this.fileErrors ? this.fileErrors[this.fileErrors.length - 1] : []
  }

  get lastDeliveryHasErrors() {
    return this.fileErrors[this.fileErrors.length - 1]?.fileErrors.length > 0 || this.fileErrors[this.fileErrors.length - 1]?.match === false
  }

  get formatErrors() {
    const hist: any = {}
    const arr = this.lastDelivery?.fileErrors?.map((a: string) => {
      const err = a.split(';')[0]
      if (err in hist) hist[err]++; else hist[err] = 1
    })
    return hist
  }

  errorsOccurrences(error: string): any[] {
    const matchingErrors: any[] = []

    const arr = this.lastDelivery?.fileErrors?.forEach((list: any) => {
      if (list.toLocaleLowerCase().search(error.toLocaleLowerCase()) > -1) matchingErrors.push(list.split(';'))
    })

    return matchingErrors
  }
}
