
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Order } from '@/models/index'
import { launchDelivery } from '@/api/orders'

import { clipboardSuccess } from '@/utils/clipboard'

import CardDeliveries from './CardDeliveries.vue'
import { useUserStore } from '@/stores/user'

@Component({
  name: 'OrderCardDesktop',
  components: {
    CardDeliveries,
    CardDistributionTooltip: () => import('@/components/CardDistributionTooltip/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private order!: Order
  @Prop({ required: true }) private isWholesalerBuyerOrder!: boolean

  userStore: any = useUserStore()

  private deliveryLoading = false
  private clipboardSuccess = clipboardSuccess

  get multipleItemCarousels(): boolean {
    return (this.order.deliveries.length >= 1 && !this.order.isByAPI() && !this.isWholesaler) && (this.displayProcessButton || this.displayPayButton)
  }

  get isBuyer(): boolean {
    return this.userStore.roles.includes('buyer')
  }

  get isWholesaler(): boolean {
    return this.userStore.roles.includes('wholesaler')
  }

  get displayProcessButton(): boolean {
    return (this.isWholesaler && this.order.isDeferred() && this.order.isWaiting()) || (this.order.isPaid() && this.order.isWaiting() && this.isWholesalerBuyerOrder)
  }

  get displayPayButton(): boolean {
    return (this.order.isPrepaid() && !this.order.isPaid() && !this.isWholesalerBuyerOrder) || (this.order.isDeferred() && !this.order.isPaid() && !this.isWholesalerBuyerOrder && this.order.isProcessing())
  }

  created() {
    if (this.order.isPaidByPrepaidAccount()) this.order.paymentStatus = 'paid'
  }

  async launchDelivery(orderId: string): Promise<void> {
    this.deliveryLoading = true

    try {
      await launchDelivery(orderId)
      this.$notify({
        title: this.$t('orders.notification.deliverySuccess.title') as string,
        message: this.$t('orders.notification.deliverySuccess.message') as string,
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      this.$notify({
        title: this.$t('orders.notification.deliveryError.title') as string,
        message: this.$t('orders.notification.deliveryError.message') as string,
        type: 'error',
        duration: 2000
      })
    }
    this.deliveryLoading = false
  }

  private prevCarousel(orderId: string): void {
    (this.$refs[`carousel-${orderId}`] as any).prev()
  }

  private nextCarousel(orderId: string): void {
    (this.$refs[`carousel-${orderId}`] as any).next()
  }
}
